.aml-risk-settings {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  &__models {
    width: 100%;
    &__title {
      font-size: 14px;
      line-height: 24px;
      color: $gray-8;
      margin-left: 6px;
      margin-bottom: 4px;
    }
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .aml-activate-modal__radio {
        margin-right: 0;
        .ant-radio-inner {
          width: 20px;
          height: 20px;
          border: 1px solid $gray-5;
          background: $gray-1;
          &::after {
            width: 14px;
            height: 14px;
            position: absolute;
            top: 95%;
            left: 95%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    &__custom {
      margin-top: 40px;
      &__add-btn {
        height: 32px;
        margin-top: 40px;
        margin-left: auto;
        @include TransparentBtn;
      }
    }
  }
  &__model {
    width: 100%;
    height: 80px;
    padding: 0 24px;
    border: 1px solid $gray-4;
    border-radius: 8px;
    background: $gray-1;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    &:hover {
      background: $gray-light;
    }
    &:not(:first-child) {
      margin-top: 16px;
    }

    @include Transition;
    &.active {
      border: 1px solid $main;
    }
    &__name {
      @include Ellipsis;
      width: 236px;
      margin-left: 26px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    &__last-modified {
      margin-left: 26px;
      color: $gray-7;
      span {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
      }
    }
    &__rules {
      margin-left: auto;
      display: inline-flex;
      padding: 3px 8px;
      justify-content: flex-end;
      align-items: center;
      background: $gray-2;
      border-radius: 2px;
      a {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray-9;
        &:hover {
          color: $main;
          text-decoration-line: underline;
          text-decoration-thickness: 0.047rem;
          text-underline-offset: 0.094rem;
        }
      }
      &.active {
        a {
          color: $main;
          text-decoration-line: underline;
          text-decoration-thickness: 0.047rem;
          text-underline-offset: 0.094rem;
          &:hover {
            text-decoration-line: none;
          }
         }
      }
    }
    &__status-button {
      margin-left: auto;
      width: 83px;
      height: 32px;
      border-radius: 40px;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid $gray-6;
    }
    &__menu {
      margin-left: 10px;
      height: 40px;
      &__trigger {
        display: inline-block;
        width: 100%;
        svg {
          transform: rotate(90deg);
        }
        svg, path {
          fill: $gray-6;
        }
      }
    }
  }
  &__create {
    &__base-model {
      height: 40px;
      border: 1px solid $gray-5;
      &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 37px;
      }
      &.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 2px;;
      }
      & .ant-select-selector {
        height: 40px;
      }
    }
    &__btn {
      height: 40px;
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__set-risk-level {
    margin-bottom: 48px;
    &__wrapper {
      margin-top: 24px;
    }
    &__description {
      margin-bottom: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    &__select {
      width: 282px;
      height: 40px;

      .ant-select-selector {
        height: 38px !important;
      }
      .ant-select-selection-search,
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 4px !important;
      }
      .ant-select-selection-item {
        padding-top: 4px !important;
      }
      .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .aml-risk-settings {
    padding: 8px 8px 0 8px;
    &__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      &.__risk-models {
        margin-left: 16px;
        margin-bottom: 8px;
      }
    }
    &__models {
      &__title {
        margin-left: 16px;
      }
      &__custom__add-btn {
        margin: 24px auto;
        width: calc(100% - 16px);
        height: 40px;
        font-size: 14px;
        line-height: 24px;
      }
      &__menu {
        margin-left: 0;
      }
    }
    &__model__menu {
      margin-left: 0;
      height: 24px;
      &__trigger {
        display: flex;
        justify-content: end;
        svg {
          transform: rotate(180deg);
        }
        svg,path {
          fill: $gray-8;
        }
      }
    }
    &__model__rules {
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      word-wrap: unset;
      white-space: nowrap;
      width: fit-content;
    }
    &__model-mobile {
      width: 100%;
      height: 80px;
      padding: 0 14px;
      border: 1px solid $gray-4;
      border-radius: 8px;
      background: $gray-1;
      display: flex;
      align-items: center;
      flex-direction: row;
      &:not(:first-child) {
        margin-top: 8px;
      }
      &__naming {
        overflow: hidden;
        margin-right: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      &__name {
        @include Ellipsis;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-left: 14px;
      }
      &__last-modified {
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;
        margin-left: 14px;
      }
      &__rules {
        margin-left: auto;
        font-size: 11px;
        line-height: 16px;
        white-space: nowrap;
      }
      &__menu-wrapper {
        margin-top: -8px;
        &__menu {
          margin-left: 0;
          width: 24px;
          height: 24px;
        }
      }

    }
    &__set-risk-level {
      margin-bottom: 40px;
      padding: 0 16px;
      &__wrapper {
        margin-top: 0;
      }
      &__select {
        width: 100%;
      }
    }
  }
}
